// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2000-jsx": () => import("./../../../src/pages/2000.jsx" /* webpackChunkName: "component---src-pages-2000-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-become-an-animator-jsx": () => import("./../../../src/pages/become-an-animator.jsx" /* webpackChunkName: "component---src-pages-become-an-animator-jsx" */),
  "component---src-pages-cennik-i-plany-jsx": () => import("./../../../src/pages/cennik-i-plany.jsx" /* webpackChunkName: "component---src-pages-cennik-i-plany-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-discount-your-invoice-jsx": () => import("./../../../src/pages/discount-your-invoice.jsx" /* webpackChunkName: "component---src-pages-discount-your-invoice-jsx" */),
  "component---src-pages-dla-przewoznika-jsx": () => import("./../../../src/pages/dla-przewoznika.jsx" /* webpackChunkName: "component---src-pages-dla-przewoznika-jsx" */),
  "component---src-pages-dla-spedycji-jsx": () => import("./../../../src/pages/dla-spedycji.jsx" /* webpackChunkName: "component---src-pages-dla-spedycji-jsx" */),
  "component---src-pages-dubai-premiere-jsx": () => import("./../../../src/pages/dubai-premiere.jsx" /* webpackChunkName: "component---src-pages-dubai-premiere-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-financing-for-companies-comparison-jsx": () => import("./../../../src/pages/financing-for-companies-comparison.jsx" /* webpackChunkName: "component---src-pages-financing-for-companies-comparison-jsx" */),
  "component---src-pages-finansowanie-dla-firm-porownanie-jsx": () => import("./../../../src/pages/finansowanie-dla-firm-porownanie.jsx" /* webpackChunkName: "component---src-pages-finansowanie-dla-firm-porownanie-jsx" */),
  "component---src-pages-finansowanie-lancucha-dostaw-jsx": () => import("./../../../src/pages/finansowanie-lancucha-dostaw.jsx" /* webpackChunkName: "component---src-pages-finansowanie-lancucha-dostaw-jsx" */),
  "component---src-pages-for-the-carrier-jsx": () => import("./../../../src/pages/for_the_carrier.jsx" /* webpackChunkName: "component---src-pages-for-the-carrier-jsx" */),
  "component---src-pages-fundusze-europejskie-jsx": () => import("./../../../src/pages/fundusze-europejskie.jsx" /* webpackChunkName: "component---src-pages-fundusze-europejskie-jsx" */),
  "component---src-pages-health-check-jsx": () => import("./../../../src/pages/health-check.jsx" /* webpackChunkName: "component---src-pages-health-check-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-koszyk-podsumowanie-jsx": () => import("./../../../src/pages/koszyk/podsumowanie.jsx" /* webpackChunkName: "component---src-pages-koszyk-podsumowanie-jsx" */),
  "component---src-pages-koszyk-wybor-opcji-produktu-jsx": () => import("./../../../src/pages/koszyk/wybor-opcji-produktu.jsx" /* webpackChunkName: "component---src-pages-koszyk-wybor-opcji-produktu-jsx" */),
  "component---src-pages-pokonaj-inflacje-jsx": () => import("./../../../src/pages/pokonaj-inflacje.jsx" /* webpackChunkName: "component---src-pages-pokonaj-inflacje-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-protect-money-from-inflation-jsx": () => import("./../../../src/pages/protect-money-from-inflation.jsx" /* webpackChunkName: "component---src-pages-protect-money-from-inflation-jsx" */),
  "component---src-pages-supply-chain-financing-jsx": () => import("./../../../src/pages/supply-chain-financing.jsx" /* webpackChunkName: "component---src-pages-supply-chain-financing-jsx" */),
  "component---src-pages-zamien-faktury-na-gotowke-jsx": () => import("./../../../src/pages/zamien-faktury-na-gotowke.jsx" /* webpackChunkName: "component---src-pages-zamien-faktury-na-gotowke-jsx" */),
  "component---src-pages-zarzadzanie-plynnoscia-zgodnie-z-esg-jsx": () => import("./../../../src/pages/zarzadzanie-plynnoscia-zgodnie-z-esg.jsx" /* webpackChunkName: "component---src-pages-zarzadzanie-plynnoscia-zgodnie-z-esg-jsx" */),
  "component---src-pages-zostan-animatorem-jsx": () => import("./../../../src/pages/zostan-animatorem.jsx" /* webpackChunkName: "component---src-pages-zostan-animatorem-jsx" */)
}

